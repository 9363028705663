.Overlay {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.AppLoadingElement {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    font-family: 'OpenSans';
    flex-direction: column;
    height: 100%;
}

.SignatorOkModalButton{
    background-color: #623d91 !important;
    color: white !important;
}

body {
  position: absolute;

 }

 @media screen and (min-width: 1600px) {
  body{
    position: absolute;
    width: 100%;
  }
 }
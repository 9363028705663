.Telephone {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
  text-align: start;
}

.Telephone > .AriaDescription {
  text-align: start;
}

.PhoneInput {
  display: flex !important;
}

:global .PhoneInputInput {
  background: none;
  outline: none;
  border: none;
}
.SignatorProfilesForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SignatorProfilesForm_Text{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    margin-bottom: 20px;
}

.SignatorProfilesForm_Text_Title{
    color: black;
    font-weight: bold;
    font-size: 22px;
}

.SignatorProfilesForm_Text_Subtitle{
    color: #8c8b8b;
    font-size: 12px;
    margin-top: 10px;
}

.SignatureStandardRadiosBody {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.SignStepButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 130px;
    color: white;
    background-color: #623d91;
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 35px;
    cursor: pointer;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 20px;
}

.SignStepButton:hover{
    background-color: #512f79;
}

.SignStepButton:disabled{
    background-color: #c2a1e3;
    cursor: default;
}

.PreviousStepButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    color: #4188bb;
    background-color: white;
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 35px;
    cursor: pointer;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 20px;
}

.PreviousStepButton:hover{
    background-color: #f3fbfa;
}
.FixTextAlignment {
  text-align: start;
}

.AriaDescriptionSpecial {
  text-align: start;
  font-style: italic;
  font-size: 0.63rem;
}

.Radio {
  text-align: left;
  max-width: 190px;
  margin-top: 7px;
  margin-bottom: 7px;
}

@media (max-width: 460px) {
  .Radio {
    margin-bottom: 15px;
  }
}

.SignatorInputRadio:checked+label {
  color: black;
  border: 1px solid #623d91;
  box-shadow: #623d91 0px 0px 5px 2px;
}

.SignatureStandardRadioFlag {
  margin-right: 4px;
  height: 26px;
}

.SignatureStandardRadioLabel {
  height: 50px;
  width: 110px;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  font-weight: 500;
  background-color: white;
  justify-content: center;
  box-shadow: 0 1px 10px 0 rgba(41, 61, 102, .3);
}

.SignatureStandardRadioLabel:hover {
  background-color: #d7c5ee;
}


.SignatureStandardRadioLabelWide {
  height: 50px;
  width: 135px;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  font-weight: 500;
  background-color: white;
  justify-content: center;
  box-shadow: 0 1px 10px 0 rgba(41, 61, 102, .3);
}

.SignatureStandardRadioLabelWide:hover {
  background-color: #d7c5ee;
}
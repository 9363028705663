
.Dropdown {
  background-color: transparent;
  border: none !important;
}

.languageIconStyle {
  color: #623d91;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
}

.DropdownMenuMainStyle{
  background-color: transparent !important;
}

.DropdownMenuMainStyle > a {
  background-color: white;
}

.languageIconStyle:hover,
.languageIconStyle:focus {
  opacity: .7;
}

.Dropdown:hover {
  background-color: transparent;
}

.Dropdown:active {
  background-color: transparent;
}

.Dropdown:focus {
  background-color: transparent;
}

.DropdownToggle {
  background-color: transparent;
  outline: none;
  border: none !important;
}

.DropdownToggle:hover {
  background-color: transparent;
}

.DropdownToggle:active {
  background-color: transparent;
}

.DropdownToggle:focus {
  background-color: transparent;
}

.DropdownToggle::after {
  color: #623d91;
  height: 6px;
}

#DropDownMenuChanges {
  border: none;
  padding: 0;
  margin-top: 5px;
}

#DropDownItemsChanges {
  max-width: 130px;
  border-radius: 5px;
  margin-bottom: 3px;
  -webkit-box-shadow: 0px 3px 5px #b2b2b4;
  -moz-box-shadow: 0px 3px 5px #b2b2b4;
  box-shadow: 0px 3px 5px 0px #b2b2b4;
}

#DropDownItemsChanges:hover,
#DropDownItemsChanges:focus {
  scale: 1.01;
  background-color: #CFC4DE;
  -webkit-box-shadow: 0px 3px 5px #623d91;
  -moz-box-shadow: 0px 3px 5px #623d91;
  box-shadow: 0px 3px 5px #623d91;
}

@media screen and (max-width: 620px) {
  .languageIconStyle > svg {
    display: none;
  }
}
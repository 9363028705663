.Toolbar {
  display: flex;
  height: 60px;
  margin: 0;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: space-between;
  position: fixed;
  top: 0;
  background-color: #f5f5f5;
  z-index: 1000;
}

.ToolbarLogo {
  height: 35px;
  margin-right: 10px;
  margin-left: 20px;
}

@media screen and (max-width: 545px) {
  .ToolbarLogo {
    height: 30px !important;
    justify-content: space-evenly;
  }

  .ToolbarLogoText {
    font-size: smaller !important;
    margin-left: 2px !important;
  }
}

.ToolbarLogoText {
  color: black;
  font-family: 'OpenSans';
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.ToolbarRightMenu {
  display: flex;
  align-items: center;
}

.ToolbarMenuLanguage {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.LogoContainer {
  display: flex;
  height: 100%;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .ToolbarMenuLanguage {
    margin-right: 5px;
  }
}

.LinkWithoutDecoration {
  text-decoration: none;
  cursor: pointer;
}
.SignContextButton{
    font-family: OpenSans !important;
    color: #1cbeda !important;
    font-size: 13px !important;
    font-weight: bold !important;
}

.DeleteContextButton{
    font-family: OpenSans !important;
    color: darkred !important;
    font-size: 13px !important;
    font-weight: bold !important;
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 500;
    src: local('OpenSans'), url(../../fonts/OpenSans-Regular.ttf) format('truetype');
}

.SignatureStandardRadiosBody {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
}

.SignatorStepContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.Signator_StepContainerAnimation{
    animation: pulse 1.25s infinite
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgb(122, 82, 182);
    }
    70% {
        -webkit-box-shadow: 0 0 0 11px rgba(94, 49, 154, 0.89);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(132, 90, 189, 0.77);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgb(122, 82, 182);
        box-shadow: 0 0 0 0 rgba(91, 44, 145, 0.58);
    }
    70% {
        -moz-box-shadow: 0 0 0 13px rgba(98, 61, 145, 0.7);
        box-shadow: 0 0 0 11px rgba(146, 101, 199, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(153, 121, 194, 0);
        box-shadow: 0 0 0 0 rgba(129, 81, 194, 0);
    }
}

@media (max-width: 530px) {
    .SignatorStepContainer {
        padding-left: 12px !important;
    }
}

.SignatorStepTitle{
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    font-family: 'OpenSans';
    color: #1cbeda;
}

.SignatorStepTitleSmall{
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'OpenSans';
    color: #1cbeda;
}

.SignatorStepSubtitle{
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: bold;
    font-family: 'OpenSans';
    color: #1cbeda;
    margin-top: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
}

.PreviousButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100px;
    color: #1cbeda;
    background-color: white;
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 35px;
    cursor: pointer;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 15px;
}

.PreviousButton:hover{
    background-color: #1cbeda;
    color: white;
}

.AboutButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100px;
    color: white;
    background-color: #1cbeda;
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 35px;
    cursor: pointer;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 15px;
}

.AboutButton:hover{
    background-color: #1aa7c0;
    color: white;
}

.SignatorSignatureProfileCardTop{
    display: flex;
    width: 95%;
    border-bottom: 1px solid #e3e3e3;
    align-items: center;
}

.SignStepButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100px;
    color: white;
    background-color: #623d91;
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 35px;
    cursor: pointer;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 15px;
}

.SignStepButton:hover{
    background-color: #512f79;
}

.SignStepButtonDisabled{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100px;
    color: white;
    background-color: #c9b9dd;
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 35px;
    cursor: default;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 15px;
}

.PreviousStepButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    color: #4188bb;
    background-color: white;
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 35px;
    cursor: pointer;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 20px;
}

.PreviousStepButton:hover{
    background-color: #f3fbfa;
}

.NextButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100px;
    color: white;
    background-color: #1cbeda;
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 35px;
    cursor: pointer;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 20px;
}

.NextButton:hover{
    background-color: #0faccb;
}

.PreviousButtonDisabled{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100px;
    color: white;
    background-color: #b0def1;
    font-size: larger;
    font-family: 'OpenSans';
    font-weight: bold;
    border-radius: 25px;
    cursor: default;
    z-index: 200;
    position: relative;
    border: 1px solid;
    margin-top: 20px;
}

.MuiStepLabel-label{
    color: red;
}

.SignatureImageOptionsActive {
    color: #5bb5f5;
    font-family: "OpenSans";
    font-size: 15px;
    font-weight: bold;
    height: 40px;
    border-right: 1px solid #5bb5f5;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.SignatorSignatureImageContainer{
    margin-top: 20px;
    display: flex;
}

.SignatureImageOptions {
    color: black;
    font-family: "OpenSans";
    font-size: 15px;
    font-weight: bold;
    height: 40px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.SignatorImageWrap{
    border: 1px dashed #ababab;
    border-radius: 5px;
    width: 56%;
    margin-left: 16px;
    max-height: 158px;
}

.Signator_FileUploadLabel{
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 15px;
}

.Signator_FileUploadInput{
    display: none;
}

.SignatorTypedSignature{
    font-family: "Caveat";
    font-size: xx-large;
    display: flex;
    height: 100%;
    color: #0000004d;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.SignatorSuccessTitle{
    margin-top: 40px;
    font-size: x-large;
    color: #13c2c2;
    font-weight: bold;
    font-family: "OpenSans";
    text-align: center;
}

.SignatorSuccessIcon{
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.SignatorErrorTitle{
    margin-top: 70px;
    font-size: x-large;
    color: red;
    font-weight: bold;
    font-family: "OpenSans";
    text-align: center;
}

.SignatorStepSubtitleButton{
    cursor: pointer;
    text-decoration: underline;
}

.SignatorErrorMessage{
    margin-top: 5px;
    font-size: medium;
    color: red;
    font-weight: bold;
    font-family: "OpenSans";
    text-align: center;
}

.SignatorErrorIcon{
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.SignatorSuccessButtonsContainer{
    display: flex;
    width: 75%;
    justify-content: space-around;
    margin-top: 25px;
    flex-wrap: wrap;
}

.SignatorSuccessButton{
    color: #623d91;
    width: 190px;
    display: flex;
    justify-content: center;
    height: 45px;
    font-size: 13px;
    background-color: #f5f5f5;
    align-items: center;
    border-radius: 25px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.SignatorSuccessButton:hover{
    background-color: #e7e7e7;
    color: #623d91;
}

.SignatorRetryButton{
    color: white;
    place-self: center;
    border-radius: 20px;
    width: 140px;
    display: flex;
    justify-content: center;
    height: 40px;
    font-size: 15px;
    background-color: #623d91;
    align-items: center;
    cursor: pointer;
    border: none;
    margin-top: 45px;
    font-weight: bold;
}

.SignatorRetryButton:hover{
    background-color: #804dc1;
}

.Signator_Signature_Options_Info{
    color: #623d91;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    font-size: 14px;
    width: 90%;
    align-items: flex-start;
}

.SignatorSignatureProfileCard{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
    cursor: pointer;
    margin-bottom: 15px;
    -webkit-user-select: none;
    user-select: none;
}

.SignatorSignatureProfileCard:hover{
    box-shadow: #623d91 0px 3px 6px, #623d91 0px 3px 6px;
    animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
}

.SignatorSignatureProfileDetails{
    width: 90%;
    font-family: 'OpenSans';
    padding: 5px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e3e3e3;
    margin: 5px;
    padding: 5px;
}

.SignatorSignatureProfileDetailsNoImage{
    width: 90%;
    font-family: 'OpenSans';
    border-bottom: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.SignatorSignatureProfileBasicDetails{
    width: 100%;
    font-family: 'OpenSans';
    padding: 1px;
    display: flex;
    flex-direction: column;
}

.SignatorSignatureProfileBasicDetailsPlus{
    width: 100%;
    font-family: 'OpenSans';
    padding: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.SignatorSignatureProfileSignatureDetails{
    width: 95%;
    font-family: 'OpenSans';
    padding-top: 10px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.SignatorStepTitleError{
    font-size: 24px;
    font-weight: bold;
    font-family: 'OpenSans';
    color: #623d91;
    color: red;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Signator_SignatureOptionsInfoText{
    display: flex;
    width: 100%;
    font-size: 15px;
    margin-bottom: 4px;
    justify-content: space-between;
}

.Signator_Drawer_Container{
    width: 80%;
    margin-top: 10px;
}

.Signator_StepTwoContainerAnimation{
    animation: pulse 1.25s infinite;
}

.Signator_ConfirmButtonContainer{
    align-items: center;
}

@media (max-width: 500px) {
    .Signator_ConfirmButtonContainer {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 770px) {
    .SignatorStepSubtitle {
        font-size: 12px;
    }
}

@media (max-width: 1400px) {
    .Signator_SignatureProfileNoImage {
        width: 90% !important;
        font-size: small !important;
    }
    .SignatorSignatureProfileCard{
        font-size: smaller !important;
    }
    .Signator_SignatureProfileWithImage{
        width: 90% !important;
        font-size: small !important;
    }
}

@media (max-width: 1451px) {
    .Signator_StepTwoContainer {
        flex-direction: column;
    }
    .Signator_StepTwoContainerAnimation {
        flex-direction: column;
    }
    .Signator_StepTwoBodyContainer{
        margin-top: 20px;
    }
}

@media (max-width: 1200px) {
    .Signator_SignatureProfileNoImage {
        width: 75% !important;
        font-size: small !important;
    }
    .Signator_SignatureProfileWithImage{
        width: 75% !important;
        font-size: small !important;
    }
}

.Signator_SignatureImageInProfile{
    margin-bottom: 5px;
    margin-top: 5px;
}


@media (max-width: 800px) {
    .Signator_SignatureImageInProfile{
        height: 50px !important;
        width: 100px !important;
    }
    .Signator_SignatureProfileSignatureDetails_Text{
        font-size: 10px !important;
    }
    .Signator_SignatureProfileNoImage {
        width: 85% !important;
        font-size: small !important;
    }
    .Signator_SignatureProfileWithImage{
        width: 85% !important;
        font-size: small !important;
    }
    .SignatorSuccessButtonsContainer{
        margin-top: 0px;
    }
}

@media (max-width: 530px) {
    .Signator_Stepper_Label > * > * {
        font-size: 9px !important;
    }
    .Signator_SignatureProfileNoImage {
        width: 90% !important;
        font-size: small !important;
    }
    .Signator_SignatureProfileWithImage{
        width: 90% !important;
        font-size: small !important;
    }
}

@media (max-width: 540px) {
    .SignatureImageOptionsActive{
        font-size: 11px;
        height: 28px;
    }
    .SignatureImageOptions{
        font-size: 11px;
        height: 28px;
    }
    .SignatorImageWrap{
        max-height: 120px;
    }
}

.SignatorStepperLabel{
    cursor: pointer;
}

@media (max-width: 550px) {
    .SignatorStepperLabel > * > *{
        font-size: 9px !important;
    }
    .SignatorStepTitle{
        font-size: 16px;
        margin-bottom: 5px;
    }
    .SignatorStepTitleSmall{
        font-size: 13px;
        margin-bottom: 20px;
    }
    .Signator_SignatureProfileNameContainer{
        width: 100% !important;
    }
    .Signator_SignatureOptionsInfoText{
        font-size: 12px !important;
        align-items: center;
        margin-bottom: 7px;
    }
    .Signator_Signature_Options_Info{
        width: 100%;
    }
    .Signator_Drawer_Container{
        width: 100%;
    }
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(1px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-1px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(1px, 0, 0);
    }
}